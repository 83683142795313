/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.match').matchHeight();


        function toggleMenu(){
          $('#burger span').toggleClass('icon-menu icon-close');
          $('body').toggleClass('show-menu');
        }

        $('#burger').on('click', toggleMenu);

        // Silly Wordpress
        $("#commentform").removeAttr("novalidate");

        //  SMOOTH SCROLLY
          $('a[href*="#"]:not([href="#"])').click(function() {
              if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                  var target = $(this.hash);
                  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                  if (target.length) {
                      $('html,body').animate({
                          scrollTop: target.offset().top
                      }, 600);
                      return false;
                  }
              }
          });

        // Sticks nav when touches top of window
        function sticky_relocate() {
          var window_top = $(window).scrollTop();
          var div_top = $('#content-anchor').offset().top;

          $('#sticky-phantom').height( $('header').outerHeight() );

          if (window_top > div_top) {
            $('header').addClass('stick');
            $('#sticky-phantom').show();
          } else {
            $('header').removeClass('stick');
            $('#sticky-phantom').hide();
          }
        }

        $(window).on('scroll', sticky_relocate);
        sticky_relocate();

      },
      finalize: function() {

      }
    },
    // Home page
    'home': {
      init: function() {

        // if ( $(window).width() > 1023) {
        //   $(document).scrollsnap({
        //     snaps: 'section:not(#act-five)',
        //     proximity: 300,
        //     duration: 600,
        //     easing: 'easeInOutQuad',
        //   });
        // }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // Moving modals due to some css clashes
        // z-index was being killed by a parent div :(
        $('.video-modal').each(function(){
          $(this).appendTo('body');
        });


        $('body').scrollspy({ 
          target: '#navigate-acts',
          offset: 100
        });

        // When closing modal, also stop iframe 
        // (by removing src and readding)
        $('.video-modal').on('click', function(){
          var targetedModal = $(this);
          var iframe = targetedModal.find('iframe');
          var iframeSrc = targetedModal.find('iframe').prop('src');

          // wait for modal to closed before checking
          setTimeout(function() {
            if (! targetedModal.hasClass('in')) {
              iframe.attr('src', '').attr('src', iframeSrc);
            }
          }, 10);
        });

        var mobileSlides = [];
        $('.mobile-slides').each( function(i, el){
          mobileSlides.push( $(this).attr('data-url') );
        });

        $("#act-one").backstretch(mobileSlides, { duration: 3000, fade: 750 });

      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        $.fn.matchHeight._maintainScroll = true;

        $('.wpcf7-form').on('submit', function(){
          setTimeout(function() {
            $('.match').matchHeight();

            console.log('bats');
          }, 400);


        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.